<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-select filterable v-model="listQuery.shop_id" placeholder="请选择店铺" class="filter-item" style="width: 220px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
      </el-select>

      <el-select filterable v-model="listQuery.device_id" placeholder="请选择设备编号" class="filter-item" style="width: 220px" clearable>
        <el-option v-for="item in cashierDevices" :key="item.id" :label="item.device_id" :value="item.device_id"></el-option>
      </el-select>

      <el-date-picker v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="filter-item" style="display: flex;" clearable></el-date-picker>

      <el-button type="primary" class="filter-item" @click="handleFilter">搜索</el-button>

      <download-excel :fields="json_fields" :fetch="handleExport" type="x ls" name="扫码机明细.xls" worksheet="扫码机明细">
        <el-button type="success" :loading="exportLoading">导出</el-button>
      </download-excel>
    </div>

    <div v-loading="listLoading">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ payMoney || 0 }}</div>
            <div class="subtitle">支付金额总计</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ payCount || 0 }}</div>
            <div class="subtitle">支付数量总计</div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-tabs v-model="listQuery.type" type="border-card" @tab-click="handleFilter" class="filter-container">
      <el-tab-pane label="在线支付机器" :name="1">
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
          <el-table-column label="ID" width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>

          <el-table-column label="订单号" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.order_no }}
            </template>
          </el-table-column>

          <el-table-column label="姓名" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.user ? scope.row.user.name : "" }}
            </template>
          </el-table-column>

          <el-table-column label="手机号码" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.user ? scope.row.user.phone : "" }}
            </template>
          </el-table-column>

          <el-table-column label="店铺名称" min-width="110" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.shop.shop_name }}</div>
            </template>
          </el-table-column>

          <el-table-column label="设备编号" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.device_id }}
            </template>
          </el-table-column>

          <el-table-column label="支付状态" min-width="110" align="center">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.pay_status === 0">待支付</el-tag>
              <el-tag type="success" v-else-if="scope.row.pay_status === 1">已支付</el-tag>
              <el-tag type="warning" v-else-if="scope.row.pay_status === 2">已退款</el-tag>
              <el-tag type="danger" v-else-if="scope.row.pay_status === 3">已取消</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="支付类型" min-width="110" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.pay_type === 1">微信</el-tag>
              <el-tag type="warning" v-else-if="scope.row.pay_type === 2">包餐套票</el-tag>
              <el-tag type="danger" v-else-if="scope.row.pay_type === 3">教师卡</el-tag>
              <el-tag type="primary" v-else-if="scope.row.pay_type === 4">支付宝</el-tag>
              <el-tag type="success" v-else-if="scope.row.pay_type === 5">富友微信</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="支付金额" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.pay_money }}
            </template>
          </el-table-column>

          <el-table-column label="下单时间" min-width="160" align="center">
            <template slot-scope="scope">
              {{ scope.row.created_at }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="包餐抵扣码机器" :name="2">
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
          <el-table-column label="ID" width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.id }}
            </template>
          </el-table-column>

          <el-table-column label="订单号" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.order_no }}
            </template>
          </el-table-column>

          <el-table-column label="姓名" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.user ? scope.row.user.name : "" }}
            </template>
          </el-table-column>

          <el-table-column label="手机号码" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.user ? scope.row.user.phone : "" }}
            </template>
          </el-table-column>

          <el-table-column label="店铺名称" min-width="110" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.shop.shop_name }}</div>
            </template>
          </el-table-column>

          <el-table-column label="设备编号" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.device_id }}
            </template>
          </el-table-column>

          <el-table-column label="支付状态" min-width="110" align="center">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.pay_status === 0">待支付</el-tag>
              <el-tag type="success" v-else-if="scope.row.pay_status === 1">已支付</el-tag>
              <el-tag type="warning" v-else-if="scope.row.pay_status === 2">已退款</el-tag>
              <el-tag type="danger" v-else-if="scope.row.pay_status === 3">已取消</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="支付类型" min-width="110" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.pay_type === 1">微信</el-tag>
              <el-tag type="warning" v-else-if="scope.row.pay_type === 2">包餐套票</el-tag>
              <el-tag type="danger" v-else-if="scope.row.pay_type === 3">教师卡</el-tag>
              <el-tag type="primary" v-else-if="scope.row.pay_type === 4">支付宝</el-tag>
              <el-tag type="success" v-else-if="scope.row.pay_type === 5">富友微信</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="支付金额" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.pay_money }}
            </template>
          </el-table-column>

          <el-table-column label="下单时间" min-width="160" align="center">
            <template slot-scope="scope">
              {{ scope.row.created_at }}
            </template>
          </el-table-column>

          <el-table-column label="包餐时间段" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.order_snap.system_catered_time ? scope.row.order_snap.system_catered_time.name : "" }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import downloadExcel from "vue-json-excel";

  export default {
    components: {
      downloadExcel
    },
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          shop_id: "",
          type: 1,
          device_id: "",
          start_date: "",
          end_date: "",
        },
        shops: [],
        cashierDevices: [],
        dateArr: null,
        json_fields: {
          订单号: {
            field: "order_no",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          姓名: "user.name",
          手机号码: {
            field: "user.phone",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          店铺名称: "shop.shop_name",
          设备编号: {
            field: "device_id",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          支付状态: {
            field: "pay_status",
            callback: (value) => {
              if (value === 0) return "待支付";
              else if (value === 1) return "已支付";
              else if (value === 2) return "已退款";
              else if (value === 3) return "已取消";
              else return "";
            }
          },
          支付类型: {
            field: "pay_type",
            callback: (value) => {
              if (value === 1) return "微信";
              else if (value === 2) return "包餐套票";
              else if (value === 3) return "教师卡";
              else if (value === 4) return "支付宝";
              else if (value === 5) return "富友微信";
              else return "";
            }
          },
          支付金额: "pay_money",
          下单时间: {
            field: "created_at",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
          包餐时间段: {
            field: "order_snap.system_catered_time.name",
            callback: (value) => {
              return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
            }
          },
        },
        exportLoading: false,
        payMoney: 0,
        payCount: 0,
      };
    },
    created() {
      if (this.$route.query.shop_id) {
        this.listQuery.shop_id = parseInt(this.$route.query.shop_id);
      }
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.listQuery.start_date = this.$route.query.start_date;
        this.listQuery.end_date = this.$route.query.end_date;
        this.dateArr = [this.listQuery.start_date, this.listQuery.end_date];
      }
      this.getList();
      this.getShopList();
      this.getCashierDeviceList();
    },
    computed: {
      ...mapGetters(["schools", "school_id", "user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getShopList();
        this.getCashierDeviceList();
      },
      "listQuery.shop_id": {
        handler(newValue, oldValue) {
          this.listQuery.device_id = "";
          this.getCashierDeviceList();
        },
        deep: true
      },
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_date = newVal[0];
          this.listQuery.end_date = newVal[1];
        } else {
          this.listQuery.start_date = "";
          this.listQuery.end_date = "";
        }
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/shop/cashierDeviceOrder",
          method: "get",
          params: this.listQuery,
          timeout: 6000000,
        }).then((response) => {
          this.list = response.data.list.data;
          this.total = response.data.list.total;
          this.payMoney = response.data.payMoney;
          this.payCount = response.data.payCount;
          this.listLoading = false;
        });
      },
      getShopList() {
        request({
          url: "/api/backend/shop/list",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            school_id: this.school_id,
          }
        }).then(response => {
          this.shops = response.data.data;
        });
      },
      getCashierDeviceList() {
        request({
          url: "/api/backend/shop/cashierDeviceList",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            shop_id: this.listQuery.shop_id,
          }
        }).then(response => {
          this.cashierDevices = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      handleExport() {
        if (!this.listQuery.start_date || !this.listQuery.end_date) {
          this.$message({
            type: "warning",
            message: "请选择时间段"
          });
          return;
        }

        let listQuery = JSON.parse(JSON.stringify(this.listQuery));
        listQuery.page = 1;
        listQuery.limit = this.total;
        this.exportLoading = true;
        return request({
          url: "/api/backend/shop/cashierDeviceOrder",
          method: "get",
          params: listQuery,
          timeout: 6000000,
        }).then(response => {
          if (response.data.list.data.length <= 0) {
            this.$message({
              type: "warning",
              message: "暂无记录"
            });
            return;
          }
          return response.data.list.data;
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.exportLoading = false;
        });
      },
    },
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }
  .el-col{
    margin-bottom: 20px;
  }
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
